import { createContext, useEffect, useReducer } from 'react'
import { debounce as _debounce } from 'lodash-es'
import { getWindowDetails } from 'utils/viewport'
const LayoutContext = createContext()

// default to iphone size - xs
const DEFAULT_SIZE_SMALL = Object.freeze({
  breakpointPixelSize: 320,
  breakpointLetterSize: 'xs',
  screenWidth: 390,
  screenHeight: 844,
  calculatedClient: false,
})

// 1280x800px `xl` default desktop size
const DEFAULT_SIZE = Object.freeze({
  breakpointPixelSize: 1280,
  breakpointLetterSize: 'xl',
  screenWidth: 1281,
  screenHeight: 800,
  calculatedClient: false,
})

const layoutReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MENU_CONDENSED':
      return {
        ...state,
        menuCondensed: action.value,
      }
    case 'SET_VIEWPORT_SIZE':
      return {
        ...state,
        viewportSize: action.value,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const LayoutProvider = ({ children, isMobileUA = false }) => {
  const [state, dispatch] = useReducer(layoutReducer, {
    isMobileUA,
    menuCondensed: false,
    viewportSize: isMobileUA ? DEFAULT_SIZE_SMALL : DEFAULT_SIZE,
  })
  useEffect(() => {
    function getViewPortData() {
      const result = getWindowDetails()
      return result ?? (isMobileUA ? DEFAULT_SIZE_SMALL : DEFAULT_SIZE)
    }

    const handleResize = _debounce(
      () => {
        dispatch({ type: 'SET_VIEWPORT_SIZE', value: getViewPortData() })
      },
      250,
      { maxWait: 250 }
    )

    window.addEventListener('resize', handleResize)
    dispatch({ type: 'SET_VIEWPORT_SIZE', value: getViewPortData() })

    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileUA])

  return <LayoutContext.Provider value={{ dispatch, state }}>{children}</LayoutContext.Provider>
}

export { LayoutProvider, LayoutContext }
